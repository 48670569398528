import Head from 'next/head';

function CustomHeader({ description, title }: { description?: string; title?: string }) {
  if (!description && !title) return <></>;

  const newTitle = `${title} - tv4.se`;

  return (
    <Head>
      {description && <meta name="description" content={description} />}
      {title && <title>{newTitle}</title>}
    </Head>
  );
}

export { CustomHeader };

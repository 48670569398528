import Head from 'next/head';
import React from 'react';

interface IProps {
  title?: string;
  description?: string;
  image?: string;
  video?: string;
  url?: string;
}

const OgTags = ({ title, description, image, video, url }: IProps) => {
  return (
    <Head>
      {title && (
        <>
          <meta property="og:title" content={title} />
          <meta name="twitter:title" content={title} />
        </>
      )}
      {description && (
        <>
          <meta property="og:description" content={description} />
          <meta name="twitter:description" content={description} />
        </>
      )}
      {image && (
        <>
          <meta property="og:image" content={image} />
          <meta name="twitter:image" content={image} />
        </>
      )}
      {video && <meta property="og:video" content={video} />}
      {url && <meta property="og:url" content={url} />}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@Nyheterna" />
    </Head>
  );
};

export { OgTags };

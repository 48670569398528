import React, { useMemo } from 'react';
import type { ImageObject, NewsArticle, Person, VideoObject, WithContext } from 'schema-dts';

import type {
  ArticleFieldsFragment,
  ImageFieldsFragment,
  VideoAssetFieldsFragment,
  VideoPlayerDataFieldsFragment,
} from '@news/gql';
import { type NewsEntry, getBylines } from '@news/lib';

import { JsonLd, publisher } from './shared';

interface Props {
  item: NewsEntry;
}

/**
 * Creates a JSON-LD schema for a news entry.
 * You can try out the schema with Google's Structured Data Testing Tool: https://search.google.com/test/rich-results
 */
export const NewsEntrySchema = ({ item }: Props) => {
  const data = useMemo(() => {
    return getData(item);
  }, [item]);

  return <JsonLd json={data} />;
};

function getData(item: NewsEntry): WithContext<NewsArticle | VideoObject> {
  switch (item.__typename) {
    case 'Article':
      return articleSchema(item);
    case 'VideoAsset':
      return {
        '@context': 'https://schema.org',
        ...videoObjectSchema(item),
      };
  }
}

/**
 * Docs from Google: https://developers.google.com/search/docs/appearance/structured-data/article
 */
function articleSchema(item: ArticleFieldsFragment): WithContext<NewsArticle> {
  return {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    publisher: publisher,
    headline: item.title,
    description: item.lead ?? undefined,
    datePublished: item.publishedDateTime ?? undefined,
    dateModified: item.updatedDateTime ?? undefined,
    author: authorsSchema(item),
    wordCount: item.metadata?.wordCount ?? undefined,
    ...featuredAssetSchema(item.featuredAsset),
  };
}

function featuredAssetSchema(item: ArticleFieldsFragment['featuredAsset']): Partial<NewsArticle> {
  switch (item?.__typename) {
    case 'VideoPlayerData':
      return {
        image: videoPlayerDataThumbnail(item),
        video: videoPlayerDataSchema(item),
      };
    case 'Image':
      return {
        image: imageSchema(item),
      };
    default:
      return {};
  }
}

function videoPlayerDataSchema(item: VideoPlayerDataFieldsFragment): VideoObject | undefined {
  if (!item.asset) {
    return undefined;
  }
  const { thumbnailUrl, ...videoObject } = videoObjectSchema(item.asset);
  const thumbnail = videoPlayerDataThumbnail(item);
  if (thumbnail) {
    return {
      ...videoObject,
      thumbnail,
    };
  }
  return {
    ...videoObject,
    thumbnailUrl,
  };
}

function videoPlayerDataThumbnail(item: VideoPlayerDataFieldsFragment): ImageObject | undefined {
  if (!item.thumbnail) {
    return undefined;
  }
  return {
    '@type': 'ImageObject',
    contentUrl: item.thumbnail,
    creditText: item.thumbnailByline ?? undefined,
  };
}

function authorsSchema(article: ArticleFieldsFragment): Person[] {
  const bylines = getBylines(article);

  const authors = bylines.map((byline) => {
    const author: Person = {
      '@type': 'Person',
      name: byline.name,
      email: byline.email ?? undefined,
    };
    if (byline.email) author.email = byline.email;
    return author;
  });
  return authors;
}

/**
 * Docs: https://developers.google.com/search/docs/appearance/structured-data/image-license-metadata
 */
function imageSchema(item: ImageFieldsFragment): ImageObject | undefined {
  if (!item.url) {
    return undefined;
  }
  return {
    '@type': 'ImageObject',
    name: item.caption ?? undefined,
    contentUrl: item.url,
    creditText: item.byline ?? undefined,
  };
}

/**
 * Docs from Google: https://developers.google.com/search/docs/appearance/structured-data/video#json-ld
 */
function videoObjectSchema(item: VideoAssetFieldsFragment): VideoObject {
  return {
    '@type': 'VideoObject',
    publisher: publisher,
    contentUrl: `https://www.tv4.se/klipp/va/${item.id}${item.slug ? `/${item.slug}` : ''}`,
    name: item.title,
    uploadDate: item.publishedDateTime ?? undefined,
    description: item.description ?? 'Se detta och fler klipp på tv4.se',
    duration: `PT${item.duration}S`,
    thumbnailUrl: item.image ?? undefined,
  };
}

import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';

import { ServerContext } from 'contexts/ServerContext';

const Redirect = ({ to, statusCode = 301 }: { to: string; statusCode?: number }) => {
  const router = useRouter();
  const serverContext = useContext(ServerContext);

  if (serverContext && serverContext.response) {
    serverContext.response.writeHead(statusCode, { location: to });
    serverContext.response.end();
  }

  useEffect(() => {
    // In case this component gets rendered client-side. We can use the router in order to redirect the user.
    router.push(to);
  }, [router, to]);

  return null;
};

export { Redirect };

import Head from 'next/head';
import React from 'react';
import type { ImageObject, NewsMediaOrganization, PostalAddress, Thing, WithContext } from 'schema-dts';

const address: PostalAddress = {
  '@type': 'PostalAddress',
  addressRegion: 'Stockholm',
  addressCountry: 'SE',
  addressLocality: 'Stockholm',
  postalCode: '115 79',
  streetAddress: 'Tegeluddsvägen 3-5',
  email: 'info@tv4.se',
  telephone: '08-459 40 00',
};

const logo: ImageObject = {
  '@type': 'ImageObject',
  url: 'https://www.tv4.se/icons/icon-128x128.png',
};

export const publisher: NewsMediaOrganization = {
  '@type': 'NewsMediaOrganization',
  name: 'TV4 Nyheterna',
  url: 'https://www.tv4.se',
  masthead: 'https://www.tv4.se',
  address: address,
  logo: logo,
  image: logo,
};

function JsonLd<T extends Thing>({ json }: { json: WithContext<T> }) {
  return (
    <Head>
      <script
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(json),
        }}
        type="application/ld+json"
      />
    </Head>
  );
}

export { JsonLd };

import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { sizeUnits } from '@news/design-tokens/src/sizeUnits';

import { Typography } from 'components/Typography';
import { SecondaryButton } from 'components/buttons';

const LoadMoreButton = ({ nextPage, onClick }: { nextPage: number; onClick: () => void }) => {
  const observerRef = useRef<HTMLDivElement>(null);

  const haveNextPage = nextPage > -1;
  const loadOnScroll = haveNextPage && nextPage < 6;

  useEffect(() => {
    const element = observerRef.current;

    const callbackFunction = ([entry]: IntersectionObserverEntry[]) => {
      if (loadOnScroll && entry?.isIntersecting) {
        onClick();
      }
    };

    const observer = new IntersectionObserver(callbackFunction, {
      root: null,
      rootMargin: '500px',
      threshold: 0,
    });

    if (element) observer.observe(element);

    return () => {
      if (element) observer.unobserve(element);
    };
  }, [observerRef, onClick, loadOnScroll]);

  return (
    <ButtonWrapper ref={observerRef}>
      {haveNextPage && !loadOnScroll && (
        <Button type="button" onClick={onClick}>
          <Typography variant="CTA">Hämta fler</Typography>
        </Button>
      )}
    </ButtonWrapper>
  );
};

const Button = styled(SecondaryButton)`
  padding: ${sizeUnits[12]} ${sizeUnits[32]};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 9px;
`;

export { LoadMoreButton };

import { type NewsEntry, getImageFromFeatured } from '@news/lib';

export const feedItemImage = (feedItem: NewsEntry): string | undefined => {
  switch (feedItem.__typename) {
    case 'Article':
      return getImageFromFeatured(feedItem.featuredAsset);
    case 'VideoAsset':
      return feedItem.image ?? undefined;
  }
};

import Head from 'next/head';
import React from 'react';

import { isStage } from 'lib/helpers';

const Canonical = ({ path }: { path?: string | null }) => {
  if (!path) return <></>;

  const host = isStage() ? 'beta1.tv4-stage.se' : 'www.tv4.se';
  const link = `https://${host}${path.split('?')[0]}`;

  return (
    <Head>
      <link rel="canonical" href={link}></link>
    </Head>
  );
};

export { Canonical };

import React from 'react';
import type { CollectionPage, WithContext } from 'schema-dts';

import type { Maybe } from '@news/gql';

import { JsonLd, publisher } from './shared';

type Props = {
  feed: { title?: Maybe<string>; metaDescription?: Maybe<string> };
};

//TODO: fix gql types
const CollectionPageSchema = ({ feed }: Props) => {
  const data: WithContext<CollectionPage> = {
    '@context': 'https://schema.org',
    '@type': 'CollectionPage',
  };

  data.headline = feed.title ?? undefined;
  data.description = feed.metaDescription ?? undefined;
  data.publisher = publisher;

  return <JsonLd json={data} />;
};

export { CollectionPageSchema };

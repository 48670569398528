import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import type { PaginatedFeedQueryVariables } from '@news/gql';
import { usePaginatedFeedQuery } from '@news/gql';
import {
  FALLBACK_OG_IMAGE,
  getMetaDescription,
  getMetaTitle,
  getOgDescription,
  getOgTitle,
  getVideoAssetIds,
  isNotDeprecatedNewsFeedItem,
} from '@news/lib';

import { Canonical } from 'components/Canonical';
import { CustomHeader } from 'components/CustomHeader';
import { OgTags } from 'components/OgTags';
import { AppLinksMeta } from 'components/meta/applinks';
import { Redirect } from 'components/redirect';
import { CollectionPageSchema } from 'components/schema/collectionPage';
import { usePageContext } from 'contexts/PageContext';
import { notifyBugsnag } from 'lib/bugsnagClient';
import { isClient } from 'lib/helpers';
import { ImageProxy } from 'lib/imageProxy';

import { LoadMoreButton } from './components/loadMoreButton';

const NewsFeedContainer = dynamic(() => import('components/newsfeed').then((module) => module.NewsFeed));
const Loading = dynamic(() => import('components/loading').then((module) => module.Loading));
const CustomError = dynamic(() => import('components/error').then((module) => module.CustomError));
const Error404 = dynamic(() => import('views/error/404').then((module) => module.Error404));

const Feed = ({ slug }: { slug?: string }) => {
  const { updatePageInfo } = usePageContext();
  const router = useRouter();
  const feedSlug = slug ?? '/';
  const fetchPolicy = 'cache-first';
  const variables: PaginatedFeedQueryVariables = {
    slug: feedSlug,
    page: 0,
    excludeVideoAssetList: [],
    limit: 5,
  };

  const {
    refetch: _refetch,
    data,
    error,
    loading,
    called,
    fetchMore,
    previousData,
  } = usePaginatedFeedQuery({
    variables,
    fetchPolicy,
  });
  let newsFeed = data?.paginatedNewsFeed;
  if (previousData?.paginatedNewsFeed?.slug === feedSlug && data?.paginatedNewsFeed?.slug !== feedSlug) {
    newsFeed = previousData?.paginatedNewsFeed;
  }

  const nextPage = data?.paginatedNewsFeed?.feed?.pagingData?.nextPage ?? 1;

  const loadMore = () => {
    if (nextPage === -1) {
      return false;
    }

    const excludeVideoList = getVideoAssetIds(
      data?.paginatedNewsFeed?.feed?.items.filter(isNotDeprecatedNewsFeedItem) ?? []
    );

    fetchMore({
      variables: {
        ...variables,
        page: nextPage,
        excludeVideoAssetList: excludeVideoList,
      },
    });
  };

  useEffect(() => {
    if (!newsFeed) {
      return;
    }
    updatePageInfo({
      pageData: {
        page_path: router.asPath,
        page_id: newsFeed.id,
        page_feed: newsFeed.slug,
        page_title: newsFeed.title ?? '',
        page_type: 'feed',
        page_tags: null,
      },
      kilkayaData: {
        imageUrl: newsFeed.main16x9Annotated ?? newsFeed.ogImage ?? undefined,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsFeed?.id, updatePageInfo]);

  if (!data?.paginatedNewsFeed?.slug && data?.paginatedNewsFeed?.redirect) {
    return <Redirect to={data.paginatedNewsFeed.redirect} statusCode={301} />;
  }

  const isErrorOnServer = error && !isClient;

  //If an error occurs on the server, it should return the loading-component
  //as the query is going to be rerun och the client. If it doesn't, we're getting a hydration error.
  if (!called || (!newsFeed && loading) || isErrorOnServer) {
    return <Loading />;
  } else if (error) {
    notifyBugsnag({ error, slug: feedSlug, query: 'usePaginatedFeedQuery' });
    return <CustomError statusCode={500} />;
  } else if (!newsFeed && called) {
    return <Error404 />;
  } else if (!newsFeed) {
    return <Loading />;
  }

  const ogImage = newsFeed.ogImage ? `https:${newsFeed.ogImage}` : (newsFeed.main16x9Annotated ?? FALLBACK_OG_IMAGE);
  const compressedOGImage = ImageProxy.url({ url: ogImage, width: 1200 });
  const loadMoreButton = nextPage === -1 ? null : <LoadMoreButton nextPage={nextPage} onClick={loadMore} />;

  return (
    <>
      <CustomHeader title={getMetaTitle(newsFeed)} description={getMetaDescription(newsFeed)} />
      <AppLinksMeta />

      <CollectionPageSchema feed={newsFeed} />
      <OgTags title={getOgTitle(newsFeed)} description={getOgDescription(newsFeed)} image={compressedOGImage} />
      <Canonical path={router.asPath} />
      <NewsFeedContainer loadMoreButton={loadMoreButton} newsFeed={newsFeed} />
    </>
  );
};

export { Feed };

import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';

type AppLinksProps = {
  path?: string;
};

const APP_NAME = 'TV4 Nyheterna';

// These meta tags makes the facebook app etc open the native apps when clicking links
const AppLinksMeta = ({ path }: AppLinksProps) => {
  const router = useRouter();
  const pathFromRouter = router.asPath.slice(1).split('?')[0] ?? '';
  const link = `tv4se://${path ?? pathFromRouter}`;

  return (
    <Head>
      <meta content={link} property="al:ios:url" />
      <meta content="1559547567" property="al:ios:app_store_id" />
      <meta content={APP_NAME} property="al:ios:app_name" />

      <meta content={link} property="al:android:url" />
      <meta content="com.tv4nyheterna" property="al:android:package" />
      <meta content={APP_NAME} property="al:android:app_name" />
    </Head>
  );
};

export { AppLinksMeta };

import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';

import { trackSifoEvent } from 'lib/helpers';

// Sends a tracking event to Sifo/Kantar
//
// The tracked value is based on the path:
//
// / -> /
// /senaste -> /senaste
// /nyhetsmorgon -> /nyhetsmorgon
// /sport/senaste -> /sport/senaste
// /arkiv -> /arkiv
// /arkiv/2017/06/01 -> /arkiv/2017/06/01
// /idol/medverkande -> /idol/medverkande
//
// Some paths are changed (note that the leading slash, id and slug are removed):
//
// /artikel/:id/:slug -> artikel
// /klipp/vf/:id/:slug -> klipp
// /personer/:slug -> personer

/** @internal */
export const getSifoPath = (fullPathWithQueryParams: string): string => {
  const path = fullPathWithQueryParams.split('?')[0] as string;

  if (path.startsWith('/artikel') || path.startsWith('/klipp')) {
    return path.split('/')[1] as string;
  }

  return path;
};

const useSifoTracking = (): void => {
  const lastPath = useRef('');
  const router = useRouter();

  useEffect(() => {
    if (router.asPath === lastPath.current) return;

    const path = getSifoPath(router.asPath);
    trackSifoEvent(path);

    lastPath.current = router.asPath;
  }, [router.asPath]);
};

export { useSifoTracking };

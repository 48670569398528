import type { NextPage } from 'next';
import { useRouter } from 'next/router';

import type { FeedItemType } from '@news/gql';

import { useSifoTracking } from 'hooks/useSifoTracking';
import { getCacheControl, getFeedSlug } from 'lib/helpers';
import { Feed } from 'views/feed';
import { FeedItem } from 'views/feed-item';

const FeedPage: NextPage = () => {
  useSifoTracking();
  const router = useRouter();
  const feedSlug = getFeedSlug(router);

  // Data for feed items
  const id = typeof router.query.id === 'string' ? router.query.id : undefined;
  const slug = typeof router.query.slug === 'string' ? router.query.slug : undefined;
  const type = typeof router.query.type === 'string' ? (router.query.type as FeedItemType) : undefined;

  const isFeedItemPage = !!id && !!type;
  return isFeedItemPage ? <FeedItem id={id} type={type} slug={slug} /> : <Feed slug={feedSlug} />;
};

FeedPage.getInitialProps = (context) => {
  context.res?.setHeader('Cache-Control', getCacheControl());
  return {
    props: {},
  };
};

export default FeedPage;
